import { Component, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";

import { BusinessFrontService } from "@core/services/business-front/business-front.service";
import { ThemeService } from "@core/services/theme/theme.service";
import { AnalyticsService } from "@shared/services/analytics/analytics.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(
    private themeService: ThemeService,
    private businessFrontService: BusinessFrontService,
    private analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.businessFrontService.setBusinessFront("vss");
    this.themeService.setTheme("indigo-bliss");
    this.analyticsService.initScreenViewRouting();
  }
}
