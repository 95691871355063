import { registerLocaleData } from "@angular/common";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import localePt from "@angular/common/locales/pt";
import { ErrorHandler, LOCALE_ID, importProvidersFrom } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import { BugsnagErrorHandler } from "@bugsnag/plugin-angular";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";

import { errorInterceptor } from "@core/interceptors/error/error.interceptor";

import { AppComponent } from "./app/app.component";
import { environment } from "./environments/environment";
import { routes } from "./routes";

if (environment.BUGSNAG_KEY) {
  Bugsnag.start({ apiKey: environment.BUGSNAG_KEY });
  BugsnagPerformance.start({ apiKey: environment.BUGSNAG_KEY });
}

registerLocaleData(localePt);

export function errorHandlerFactory() {
  if (environment.BUGSNAG_KEY) {
    return new BugsnagErrorHandler();
  }

  return new ErrorHandler();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(BrowserModule, MatDialogModule, NgxMaskDirective, NgxMaskPipe),
    { provide: "Window", useValue: window },
    { provide: LOCALE_ID, useValue: "pt" },
    provideHttpClient(withInterceptors([errorInterceptor])),
    provideNgxMask(),
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
    provideAnimations(),
    provideRouter(routes),
  ],
})
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
